"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformsList = void 0;
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var SubformItem_1 = require("./SubformItem");
var useSubforms_1 = require("./useSubforms");
var use_debounce_1 = require("use-debounce");
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
function SubformsList(_a) {
    var channelSlug = _a.channelSlug;
    var channel = (0, hooks_1.useGetChannel)({ channelSlug: channelSlug });
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    var _b = (0, react_1.useState)(''), _query = _b[0], setQuery = _b[1];
    var query = (0, use_debounce_1.useDebounce)(_query, 300)[0];
    var _c = (0, useSubforms_1.useSubforms)({ workflowTemplateId: workflowTemplateId, name: query }), subforms = _c.subforms, loading = _c.loading, hasMore = _c.hasMore, loadMore = _c.loadMore;
    return (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 1 },
            react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('workflowTemplate.subformsSearch') })),
        react_1.default.createElement(components_1.MBScrollView, { nativeShouldPersistTaps: true, nativeKeyboardDismissMode: "on-drag", style: { height: '100%' } },
            !loading && (0, lodash_1.isEmpty)(subforms) && (react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('workflowTemplate.subformsEmptyStateTitle'), description: (0, i18n_1.i18n)('workflowTemplate.subformsEmptyStateDescription'), image: require('@shared/assets/icons/v2/check-todo.png'), imageSize: (0, components_1.grid)(12) })),
            loading && (react_1.default.createElement(react_native_1.View, { style: {
                    height: 150,
                    alignItems: 'center',
                    justifyContent: 'center',
                } },
                react_1.default.createElement(components_1.MBLoadingIndicator, null))),
            !(0, lodash_1.isNil)(workflowTemplateId) &&
                (subforms === null || subforms === void 0 ? void 0 : subforms.map(function (_a) {
                    var subformWorkflowTemplate = _a.workflowTemplate, componentId = _a.componentId;
                    return (react_1.default.createElement(SubformItem_1.SubformItem, { key: subformWorkflowTemplate === null || subformWorkflowTemplate === void 0 ? void 0 : subformWorkflowTemplate.id, componentId: componentId, subformWorkflowTemplate: subformWorkflowTemplate, channelSlug: channelSlug, mainWorkflowTemplateId: workflowTemplateId }));
                })),
            hasMore && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(3) } },
                react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.list.showMore'), size: "small", theme: "transparent", bold: false, borderColor: styles_1.Colors.v2.gray10, onPress: loadMore, style: { alignSelf: 'center' } }))))));
}
exports.SubformsList = SubformsList;
