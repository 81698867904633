"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedCardLinkingView = void 0;
var components_1 = require("@shared/components");
var index_web_1 = require("@shared/components/scrollview/index.web");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useLinkingOptions_1 = require("@shared/scenes/workflows/edit/components/optionsMenu/relatedCardOptions/useLinkingOptions");
var use_debounce_1 = require("use-debounce");
var RelatedCardLinkingView = function (_a) {
    var _b, _c;
    var component = _a.component, workspaceDetails = _a.workspaceDetails;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _d = (0, react_1.useState)(''), _searchKeyword = _d[0], setSearchKeyword = _d[1];
    var searchKeyword = (0, use_debounce_1.useDebounce)(_searchKeyword, 500)[0];
    var _e = (0, hooks_1.useListWorkflowTemplates)({
        input: { isSubform: false, name: !(0, lodash_1.isEmpty)(searchKeyword) ? searchKeyword : undefined },
    }), workflowTemplates = _e.workflowTemplates, loading = _e.loading, hasMore = _e.hasMore, loadMore = _e.loadMore;
    var linkableWorkflows = (0, react_1.useMemo)(function () {
        return !(0, lodash_1.isEmpty)(workflowTemplates)
            ? __spreadArray([], (workflowTemplates !== null && workflowTemplates !== void 0 ? workflowTemplates : []), true).map(function (template) {
                var _a, _b, _c;
                return {
                    workflowTemplateId: template.id,
                    imageUrl: (_a = template.channelDefaultConfiguration) === null || _a === void 0 ? void 0 : _a.imageUrl,
                    name: (_c = (_b = workspaceDetails.get(template.id)) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : template.name,
                    components: template.components,
                };
            })
            : [];
    }, [workflowTemplates, workspaceDetails]);
    var selectedWorkflowTemplateId = (_b = component.workflowTemplateId) !== null && _b !== void 0 ? _b : (_c = component.cardFilter) === null || _c === void 0 ? void 0 : _c.workflowTemplateId;
    var selectedWorkflow = linkableWorkflows === null || linkableWorkflows === void 0 ? void 0 : linkableWorkflows.find(function (value) {
        return (value === null || value === void 0 ? void 0 : value.workflowTemplateId) === selectedWorkflowTemplateId;
    });
    var onSelectWorkflow = (0, useLinkingOptions_1.useLinkingOptions)(component).onSelectWorkflow;
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: 2 },
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingVertical: 2 },
            react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundInactive }, (0, i18n_1.i18n)('builder.relatedCardOptions.responseOptions'))),
        react_1.default.createElement(components_1.MBView, { paddingHorizontal: 2, paddingBottom: 2 },
            react_1.default.createElement(components_1.MBSearchBar, { value: searchKeyword, onChangeText: setSearchKeyword, placeholder: (0, i18n_1.i18n)('common.search') })),
        react_1.default.createElement(index_web_1.MBScrollView, { style: { maxHeight: (0, components_1.grid)(30) } },
            loading && (react_1.default.createElement(components_1.MBView, { paddingVertical: 4 },
                react_1.default.createElement(components_1.MBLoadingIndicator, null))), linkableWorkflows === null || linkableWorkflows === void 0 ? void 0 :
            linkableWorkflows.map(function (workflow) {
                return (react_1.default.createElement(SelectWorkflowRow, { key: workflow.workflowTemplateId, workflow: workflow, isSelected: (workflow === null || workflow === void 0 ? void 0 : workflow.workflowTemplateId) === (selectedWorkflow === null || selectedWorkflow === void 0 ? void 0 : selectedWorkflow.workflowTemplateId), onPress: onSelectWorkflow }));
            }),
            hasMore && (react_1.default.createElement(components_1.MBView, { padding: 2 },
                react_1.default.createElement(components_1.MBButton, { theme: "outline", title: (0, i18n_1.i18n)('card.list.loadMore'), onPress: loadMore, style: { alignSelf: 'center' }, size: "small" }))))));
};
exports.RelatedCardLinkingView = RelatedCardLinkingView;
var SelectWorkflowRow = function (_a) {
    var workflow = _a.workflow, isSelected = _a.isSelected, onPress = _a.onPress;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: function () { return onPress(workflow.workflowTemplateId); } },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingVertical: 0.5, paddingHorizontal: 2 },
            (0, lodash_1.isEmpty)(workflow.imageUrl) && (react_1.default.createElement(components_1.MBBoxedIcon, { source: require('@shared/assets/icons/v2/chat-bubbles-square.png'), color: colors.avatar.foreground, backgroundColor: colors.avatar.background, size: 'small' })),
            !(0, lodash_1.isEmpty)(workflow.imageUrl) && (react_1.default.createElement(components_1.MBImage, { source: { uri: workflow.imageUrl }, style: { height: (0, components_1.grid)(3), width: (0, components_1.grid)(3) } })),
            react_1.default.createElement(components_1.MBView, { fill: true, centerVertically: true, paddingLeft: 1 },
                react_1.default.createElement(components_1.MBTextBody1, null, workflow.name)),
            react_1.default.createElement(components_1.MBSelectableDot, { selected: isSelected, radio: false }))));
};
