"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectSubform = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var label_1 = require("@shared/components/blocks/utils/label");
var contentWrapper_1 = require("@shared/scenes/auth/contentWrapper");
var SubFormOptionsChildren_1 = require("@shared/scenes/workflows/edit/components/optionsMenu/SubFormOptionsChildren");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var use_debounce_1 = require("use-debounce");
var SelectSubform = function (_a) {
    var _b, _c;
    var channelSlug = _a.channelSlug, blockLabel = _a.blockLabel, workflowTemplate = _a.workflowTemplate, onSelect = _a.onSelect;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var isDesktop = (0, contentWrapper_1.useIsDesktop)();
    var navigator = (0, useNavigator_1.useNavigator)();
    var _d = (0, react_1.useState)(''), _query = _d[0], setQuery = _d[1];
    var query = (0, use_debounce_1.useDebounce)(_query, 300)[0];
    var subformComponents = (_b = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) === null || _b === void 0 ? void 0 : _b.filter(hooks_1.isSubformComponent);
    var workflowTemplateIds = (0, lodash_1.flatten)((_c = subformComponents === null || subformComponents === void 0 ? void 0 : subformComponents.map(function (component) { return component.workflowTemplateIds; })) !== null && _c !== void 0 ? _c : []);
    var _e = (0, hooks_1.useListWorkflowTemplates)({
        input: { ids: workflowTemplateIds, name: (0, lodash_1.isEmpty)(query) ? undefined : query },
    }), workflowTemplates = _e.workflowTemplates, loading = _e.loading, hasMore = _e.hasMore, loadMore = _e.loadMore;
    var shouldDisplayShadow = (workflowTemplates === null || workflowTemplates === void 0 ? void 0 : workflowTemplates.length) > 5;
    var onAdd = (0, react_1.useCallback)(function () {
        navigator.navigate('SubformsList', { channelSlug: channelSlug });
    }, [channelSlug, navigator]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.MBView, { paddingTop: 2, paddingLeft: 2, paddingBottom: 1 },
            react_1.default.createElement(label_1.MBBlockLabel, { label: blockLabel })),
        react_1.default.createElement(components_1.MBView, { paddingVertical: 1, paddingHorizontal: 1 },
            react_1.default.createElement(components_1.MBSearchBar, { value: query, onChangeText: setQuery, placeholder: (0, i18n_1.i18n)('workflowTemplate.subformsSearch') })),
        loading && (react_1.default.createElement(react_native_1.View, { style: { height: 150, alignItems: 'center', justifyContent: 'center' } },
            react_1.default.createElement(components_1.MBLoadingIndicator, null))),
        !loading && (0, lodash_1.isEmpty)(workflowTemplates) && (react_1.default.createElement(components_1.MBView, { paddingBottom: 1, centerHorizontally: true, paddingHorizontal: 2 },
            react_1.default.createElement(components_1.MBView, { center: true, padding: 1 },
                react_1.default.createElement(components_1.MBIconV2, { name: "list", color: colors.accent, size: (0, components_1.grid)(7) })),
            react_1.default.createElement(components_1.MBTextBody1, { align: "center" }, (0, i18n_1.i18n)('card.blocks.subformsInput.emptyState')))),
        react_1.default.createElement(react_native_1.ScrollView, { style: [shouldDisplayShadow && styles.scrollView] }, workflowTemplates === null || workflowTemplates === void 0 ? void 0 :
            workflowTemplates.map(function (wft) { return (react_1.default.createElement(components_1.MBTouchableOpacity, { key: wft.id, onPress: function () { return onSelect(wft.id, wft.name); } },
                react_1.default.createElement(components_1.MBHover, { hoverStyle: { backgroundColor: colors.input.backgroundInactive } },
                    react_1.default.createElement(components_1.MBView, { row: true, key: wft.id, padding: 1, paddingLeft: 2, centerVertically: true },
                        react_1.default.createElement(components_1.MBIconV2, { name: "list", color: colors.foregroundActive, size: (0, components_1.grid)(3) }),
                        react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
                        react_1.default.createElement(components_1.MBTextBody1, { color: colors.foregroundActive }, wft.name))))); }),
            hasMore && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(3) } },
                react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.list.showMore'), size: "small", theme: "transparent", bold: false, borderColor: styles_1.Colors.v2.gray10, onPress: loadMore, style: { alignSelf: 'center' } })))),
        isDesktop ? react_1.default.createElement(SubFormOptionsChildren_1.AddSubformRow, { onAdd: onAdd }) : react_1.default.createElement(components_1.MBView, { paddingTop: 1 })));
};
exports.SelectSubform = SelectSubform;
exports.SelectSubform.displayName = 'SelectSubform';
var styles = react_native_1.StyleSheet.create({
    scrollView: react_native_1.Platform.select({
        web: {
            marginBottom: 0,
        },
    }),
});
